<template>
	<div
	id="statistics"
	class="tw-p-[24px] tw-gap-[5px] tw-h-full"
	>
		<HeaderSlot
		title="Statistiques"
		subTitle="Fichiers"
		:toBackButton="{ name: 'workspaces' }"
		/>

		<div
		class="tw-w-full tw-justify-center mt-4 tw-outline-[1px] tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-flex tw-flex-wrap tw-flex-row tw-gap-[10px]"
		>
			<v-select
			dense
			:items="agencies"
			v-model="selectedAgency"
			item-text="name"
			item-value="id"
			placeholder="Filtrer par agences"
			@change="applyAgency"
			clearable
			class="lg:tw-basis-[calc(50%-5px)] tw-basis-full"
			:loading="agenciesLoading"
			/>

			<v-select
			dense
			:items="filteredSites"
			v-model="selectedSite"
			item-text="name"
			item-value="id"
			placeholder="Filtrer par site"
			@change="applySite"
			clearable
			class="lg:tw-basis-[calc(50%-5px)] tw-basis-full"
			:loading="sitesLoading"
			/>
		</div>

		<div
		class="tw-w-full tw-justify-center tw-overflow-y-auto tw-p-[1px] tw-flex tw-flex-wrap tw-gap-[10px] tw-h-[calc(100%-172px)] mt-4"
		>
			<!-- Asbestos / PAH & TH Graphs -->
			<!--<div
        v-for="(trial, id) in trialTypes"
        :key="id"
        class="lg:tw-basis-[calc(50%-5px)] tw-basis-full tw-grow tw-shrink tw-outline-[1px] tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-flex tw-flex-col tw-justify-between tw-items-center"
      >
        <div :id="trial" class="tw-w-full tw-h-[380px]"></div>
      </div>-->
			<!-- ////////// -->
			<!-- By Statuses -->
			<div
			class="lg:tw-basis-[calc(50%-5px)] tw-basis-full tw-grow tw-shrink tw-outline-[1px] tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-flex tw-flex-col tw-justify-between tw-items-center"
			>
				<div
				id="byStatus"
				class="tw-w-full tw-h-[380px]"
				/>
			</div>
			<!-- ////////// -->
			<div
			class="tw-outline-[1px] lg:tw-basis-[calc(50%-5px)] tw-basis-full tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
			>
				<div
				id="finishedCampaignsByMonth"
				class="tw-w-full tw-h-[380px]"
				/>
			</div>

			<div
			class="tw-outline-[1px] lg:tw-basis-[calc(50%-5px)] tw-basis-full tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
			>
				<div
				id="byMonthAndStatus"
				class="tw-w-full tw-h-[380px]"
				/>
			</div>

			<div
			class="tw-outline-[1px] tw-basis-full lg:tw-basis-[calc(50%-5px)] tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
			>
				<div
				id="trialByProviderAreia"
				class="tw-w-full tw-h-[380px]"
				/>
			</div>

			<div
			class="tw-outline-[1px] tw-basis-full tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
			>
				<div
				id="byMonthAndDiameter"
				class="tw-w-full tw-h-[380px]"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import amchartUtils from "../utils/amcharts.utils.js";
export default {
	name: "Statistics",
	data(){
		return {
			globalStats: {},
			byAgencies: {
				asbestos: [],
				pah: [],
				th: []
			},
			byStatuses: [],
			byMonthAndDiameter: [],
			byMonthAndStatus: [],
			byDates: [],
			deliveriesByMonth: [],
			trialByProvider: {
				AREIA: [],
				EUROFINS: [],
				ADLAB: []
			},
			trialTypes: [
				"asbestos", "pah", "th"
			],
			colors: [
				"#BE5900",
				"#FF7800",
				"#FF8B25",
				"#FFA14E",
				"#FFB725",
				"#F79909",
				"#D58100"
			],
			agencies: [],
			selectedAgency: null,
			sites: [],
			selectedSite: null,
			filteredSites: [],
			sitesLoading: true,
			agenciesLoading: true
		};
	},
	methods: {
		applyFilters(){
			this.byStatuses = [];
			this.byMonthAndDiameter = [];
			this.byMonthAndStatus = [];
			this.byDates = [];
			this.deliveriesByMonth = [];
			this.byAgencies.asbestos = [];
			this.byAgencies.pah = [];
			this.byAgencies.th = [];
			this.trialByProvider.AREIA = [];

			this.$api.statistics
			.getCoringCampaignsStatsV2(this.selectedAgency, this.selectedSite)
			.then(data => {
				this.globalStats = data;
				this.generateGraphsData();
				this.displayGraphs();
			});
		},
		applyAgency(){
			if(this.selectedAgency === null){
				this.filteredSites = this.sites;
			}
			else {
				this.filteredSites = this.sites.filter(
					site => site.agency.id === this.selectedAgency
				);
			}
			this.applyFilters();
		},
		applySite(){
			this.applyFilters();
		},
		// Charts generate data & display functions

		generateGraphsData(){
			if(Object.keys(this.globalStats).length === 0){
				return setTimeout(this.generateGraphsData, 500);
			}
			this.countByStatuses();
			this.coresByMonthAndDiameter();
			this.coresByMonthAndStatus();
			this.countByAgenciesAndProvider();
			this.finishedCampaignByMonth();
		},
		displayGraphs(){
			// Asbestos, pah & th graphs
			/*this.trialTypes.forEach(trial => {
        this.createPieChart(
          trial,
          this.byAgencies[trial],
          "Nombre d'essais " + this.$t(trial) + " par agence"
        );
      });*/
			if(Object.keys(this.globalStats["countByStatuses"]).length !== 0) this.createPieChart(
				"byStatus",
				this.byStatuses,
				"Campagnes par statut"
			);
			if(Object.keys(this.globalStats["coresByMonthAndDiameter"]).length !== 0) this.createColumnNBarStackedChart(
				"byMonthAndDiameter",
				this.byMonthAndDiameter,
				"Carottes par mois et par diamètre",
				"V",
				"Diamètres (mm)"
			);
			if(Object.keys(this.globalStats["coresByMonthAndStatus"]).length !== 0) this.createColumnNBarStackedChart(
				"byMonthAndStatus",
				this.byMonthAndStatus,
				"Carottes par mois et par statut",
				"V",
				"Statuts"
			);
			if(Object.keys(this.globalStats["countByProvider"]).length !== 0) this.createColumnNBarStackedChart(
				"trialByProviderAreia",
				this.trialByProvider.AREIA,
				"Essais AREIA par mois",
				"V",
				"Nombre d'essais"
			);
			if(
				Object.keys(this.globalStats["finishedCampaignsByMonth"]).length !== 0
			) this.createColumnNBarSimpleChart(
				"finishedCampaignsByMonth",
				this.deliveriesByMonth,
				"Livrables par mois",
				"V",
				"Nombre de rapports livrés"
			);
		},

		// Charts data manipulations functions
		getSerieKeys(datas, excludedKeys = []){
			let allKeys = [];
			datas.forEach(data => {
				Object.keys(data).forEach(key => {
					if(!excludedKeys.includes(key) && !allKeys.includes(key)){
						allKeys.push(key);
					}
				});
			});
			return allKeys;
		},
		countByStatuses(){
			if(Object.keys(this.globalStats["countByStatuses"]).length !== 0){
				this.byStatuses = Object.keys(this.globalStats["countByStatuses"])
				.map((key, index) => [
					{
						name: this.$t(key),
						value: this.globalStats["countByStatuses"][key],
						type: "Pie",
						color: this.colors[index]
					}
				])
				.flat();
			}
		},
		coresByMonthAndDiameter(){
			if(
				Object.keys(this.globalStats["coresByMonthAndDiameter"]).length !== 0
			){
				let tempArray = Object.values(
					this.globalStats["coresByMonthAndDiameter"]
				);
				let iterator = Object.keys(this.globalStats["coresByMonthAndDiameter"]);
				tempArray.forEach((element, index) => {
					this.byMonthAndDiameter.push({
						50: element[50],
						80: element[80],
						100: element[100],
						150: element[150],
						200: element[200],
						250: element[250],
						type: iterator[index]
					});
				});
			}
		},
		coresByMonthAndStatus(){
			if(Object.keys(this.globalStats["coresByMonthAndStatus"]).length !== 0){
				let tempArray = Object.values(
					this.globalStats["coresByMonthAndStatus"]
				);
				let iterator = Object.keys(this.globalStats["coresByMonthAndStatus"]);
				tempArray.forEach((element, index) => {
					this.byMonthAndStatus.push({
						Annulées: element.cancelled,
						"En attente": element.waiting,
						Extraites: element.extracted,
						"Envoyées au chargé d'affaires": element.sent_to_study_manager,
						"Envoyées au prestataire": element.sent_to_provider,
						Validées: element.validated,
						"Résultats reçus": element.results_acquired,
						type: iterator[index]
					});
				});
			}
		},
		countByAgenciesAndProvider(){
			if(Object.keys(this.globalStats["countByProvider"]).length !== 0) Object.keys(this.globalStats["countByProvider"]).map(month => {
				this.trialByProvider.AREIA.push({
					Amiante:
              this.globalStats["countByProvider"][month]["AREIA"]["asbestos"],
					HAP: this.globalStats["countByProvider"][month]["AREIA"]["pah"],
					HCT: this.globalStats["countByProvider"][month]["AREIA"]["th"],
					type: month
				});
			});
		},
		finishedCampaignByMonth(){
			if(
				Object.keys(this.globalStats["finishedCampaignsByMonth"]).length !== 0
			){
				let allDeliveries = [];
				Object.keys(this.globalStats["finishedCampaignsByMonth"]).map(month => {
					let date =
            this.globalStats["finishedCampaignsByMonth"][month]["date"];
					let count =
            this.globalStats["finishedCampaignsByMonth"][month]["count"];
					let delivery = {};
					delivery["type"] = date;
					delivery["value"] = count;
					allDeliveries.push(delivery);
				});
				this.deliveriesByMonth = allDeliveries;
			}
		},

		// Charts generation functions

		createPieChart(id, data, title){
			amchartUtils.createPieSimpleChart(id, data, title);
		},
		createColumnNBarSimpleChart(ref, data, title, direction, yAxis){
			amchartUtils.createColumnNBarSimpleChart(
				ref,
				data,
				{},
				this.getSerieKeys(data, "type"),
				title,
				direction,
				yAxis,
				false,
				true,
				0
			);
		},
		createColumnNBarStackedChart(ref, data, title, direction, axisLabel){
			amchartUtils.createColumnNBarStackedChart(
				ref,
				data,
				{},
				this.getSerieKeys(data, "type"),
				title,
				direction,
				axisLabel,
				0
			);
		}
	},
	mounted(){
		this.$api.statistics
		.getCoringCampaignsStatsV2(this.selectedAgency, this.selectedSite)
		.then(data => {
			this.globalStats = data;
			this.generateGraphsData();
			this.displayGraphs();
			this.$api.agencies.findAll().then(data => {
				this.agencies = data.sort((a, b) => a.name.localeCompare(b.name));
				this.agenciesLoading = false;
			});
			this.$api.organizations
			.getAllSitesFromOrganization("6e42295a-a6e4-43b8-84d9-d37c5d9db7b3")
			.then(data => {
				this.sites = data.sort((a, b) => a.name.localeCompare(b.name));
				this.filteredSites = data;
				this.sitesLoading = false;
			});
		});
	}
};
</script>
