<template>
	<div class="container">
		<h1>Super Login</h1>

		<p>Connectez-vous en tant que n'importe quel utilisateur.</p>

		<v-card>
			<v-card-title>Liste des utilisateurs</v-card-title>

			<v-card-text>
				<v-row>
					<v-col cols="6">
						<v-autocomplete
						v-model="selectedUser"
						placeholder="Commencez à entrer un email..."
						:items="users"
						item-text="email"
						return-object
						/>
					</v-col>

					<v-col cols="6">
						<v-card v-if="selectedUser !== null">
							<v-card-title>Informations sur l'utilisateur</v-card-title>

							<v-card-text>
								<p><b>ID : </b>{{ selectedUser.id }}</p>

								<p><b>Prénom : </b>{{ selectedUser.firstname }}</p>

								<p><b>Nom : </b>{{ selectedUser.lastname }}</p>

								<p><b>Job : </b>{{ selectedUser.job }}</p>

								<p>
									<b>Utilisateur interne ? : </b>{{ selectedUser.isInternal ? "Oui" : "Non" }}
								</p>

								<p>
									<b>Roles : </b>

									<span
									v-for="(role, index) in selectedUser.roles"
									:key="index"
									><v-chip small>
										{{ role }}
									</v-chip>&nbsp;
									</span>
								</p>

								<p>
									<b>Site : </b>{{ selectedUser.sites[0].name }} ({{
										selectedUser.sites[0].id
									}})
								</p>

								<p>
									<b>Organisation : </b>{{ selectedUser.sites[0].organization.name }} ({{
										selectedUser.sites[0].organization.id
									}})
								</p>

								<div style="text-align: center; margin-top: 20px">
									<MediumButtonSlot @click="loginAs()">
										Me connecter en tant que cet utilisateur
									</MediumButtonSlot>
								</div>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import auth from "../../service/auth";

export default {
	name: "SuperLogin",
	data(){
		return {
			users: [],
			selectedUser: null
		};
	},
	methods: {
		loginAs(){
			this.$axios
			.post("/users/" + this.selectedUser.id + "/super-login")
			.then(response => {
				localStorage.setItem("lastToken", localStorage.getItem("token"));
				localStorage.setItem("token", response.data.token);
				document.location.href = "/workspaces";
			});
		}
	},
	mounted(){
		const token = auth.decodeToken();

		if(token.roles.includes("ROLE_SUPER_ADMIN") === false){
			this.$router.push({name: "workspaces"});
		}

		this.$api.users.findAll().then(response => {
			this.users = response;
		});
	}
};
</script>
